exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-account-tsx": () => import("./../../../src/pages/account.tsx" /* webpackChunkName: "component---src-pages-account-tsx" */),
  "component---src-pages-caffelli-demo-tsx": () => import("./../../../src/pages/caffelli-demo.tsx" /* webpackChunkName: "component---src-pages-caffelli-demo-tsx" */),
  "component---src-pages-dashboards-tsx": () => import("./../../../src/pages/dashboards.tsx" /* webpackChunkName: "component---src-pages-dashboards-tsx" */),
  "component---src-pages-dashboardview-tsx": () => import("./../../../src/pages/dashboardview.tsx" /* webpackChunkName: "component---src-pages-dashboardview-tsx" */),
  "component---src-pages-forgotpassword-tsx": () => import("./../../../src/pages/forgotpassword.tsx" /* webpackChunkName: "component---src-pages-forgotpassword-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-settings-tsx": () => import("./../../../src/pages/settings.tsx" /* webpackChunkName: "component---src-pages-settings-tsx" */),
  "component---src-pages-signin-tsx": () => import("./../../../src/pages/signin.tsx" /* webpackChunkName: "component---src-pages-signin-tsx" */),
  "component---src-pages-tensor-demo-tsx": () => import("./../../../src/pages/tensor-demo.tsx" /* webpackChunkName: "component---src-pages-tensor-demo-tsx" */)
}

